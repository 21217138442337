<template>
  <div>
    <div class="pop-container">
      <div class="pop-conts">
        <div class="box_list_2row box_bd">
          <common-multi-drop-down
              ref="cmpyNmRef"
              v-model="prcsInfo.cmpyCd"
              class="w50"
              :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              :placeholder="'회사선택'"
              @change="value=>{
                  propsCmpyCd = value
                  if(!!propsCmpyCd) getDataArray()
                }"
          >
          </common-multi-drop-down>
          <ul>
            <li class="left w50">
              <div class="mgt20">*기본작업파트: 파트구분, 기록구분 수정 불가</div>
              <kendo-grid
                  ref="prodPrcsGridRef"
                  class="mgt10"
                  style="max-height:380px;"
                  :navigatable="false"
                  :reorderable="true"
                  :column-menu="false"
                  :sortable="false"
                  :selectable="'row'"
                  :data-source="prodPrcsListDataSource"
                  :columns="prodPrcsGridColumns"
                  @change="onChangeGrid"
                  :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
              >
              </kendo-grid>
            </li>
            <li class="right">
              <div class="txt_r">
              <button :disabled="!propsCmpyCd" @click="onClickProdPrcsAdd" class="mid_btn orange">신규등록</button>
              </div>
              <div class="box_moc mgt10">
                <table class="moc_table " cellpadding="0" cellspacing="0">
                  <colgroup>
                    <col width="40%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      공정적용일
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span>
                        <kendo-datepicker
                            ref="prcsRgstDateRef"
                            v-model="prcsInfo.VModelPrcsRgstDate"
                            :format="'yyyy/MM/dd'"
                            :placeholder="'년/월/일'"
                        ></kendo-datepicker>
                      </span>
                    </td>
                    <td v-else>{{ prcsInfo.prcsRgstDate }}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      공정파트구분
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span>
                        <kendo-dropdownlist
                            ref="prcsDepth1Ref"
                            v-model="prcsInfo.prcsDepth1"
                            :data-source="dataArray"
                            :disabled="prcsInfo.autoGenerateYn === 'Y'"
                            :data-value-field="'prcsDepth1'"
                            :data-text-field="'prcsDepth1Nm'"
                            :option-label="'전체'">
                        </kendo-dropdownlist>
                      </span>
                    </td>
                    <td v-else>{{ !!prcsInfo.prcsDepth1Nm ? prcsInfo.prcsDepth1Nm : ''}} </td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      <b>공정기록구분</b>
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span>
                        <input ref="prcsDepth2NmRef"
                               class="k-textbox w100"
                               :disabled="prcsInfo.autoGenerateYn === 'Y'"
                               maxlength="20"
                               v-model="prcsInfo.prcsDepth2Nm"/>
                      </span>
                    </td>
                    <td v-else>{{ !!prcsInfo.prcsDepth2Nm ? prcsInfo.prcsDepth2Nm : '' }}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      <b>작업인원(파트타임 포함)</b>
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="wkrCntRef"
                                              class="w50"
                                              :format="'###,#'"
                                              v-model="prcsInfo.wkrCnt"
                                              :max="100"
                                              :min="0">
                        </kendo-numerictextbox> 명
                      </span>
                    </td>
                    <td v-else>{{ !!prcsInfo.wkrCnt ? prcsInfo.wkrCnt + '명' : '' }}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g ">
                      <b>파트타임 인원</b>
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="evtWkrCntRef"
                                              class="w50"
                                              :format="'###,#'"
                                              v-model="prcsInfo.evtWkrCnt"
                                              :max="100"
                                              :min="0">
                        </kendo-numerictextbox> 명
                      </span>
                    </td>
                    <td v-else>{{ !!prcsInfo.evtWkrCnt ? prcsInfo.evtWkrCnt + '명' : '' }}</td>
                  </tr>

                  <tr>
                    <td class="txt_c bg_g necessary ">
                      기준런타임
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="maxRuntimeRef"
                                              class="w50"
                                              :format="'###,#'"
                                              v-model="prcsInfo.maxRuntime"
                                              :max="1439"
                                              :min="0">
                        </kendo-numerictextbox> 분
                      </span>
                    </td>
                    <td v-else>{{ prcsInfo.maxRuntime | comma }} {{ !!prcsInfo.maxRuntime ? '분' : ''}}</td>
                  </tr>

                  <tr>
                    <td class="txt_c bg_g necessary">
                      <b>작업시간</b>
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span class="k-rtl">
                        <kendo-numerictextbox ref="wkrTimeRef"
                                              class="w50"
                                              :format="'###,#'"
                                              v-model="prcsInfo.wkrTime"
                                              :max="24"
                                              :min="0">
                        </kendo-numerictextbox> 시간
                      </span>
                    </td>
                    <td v-else>{{ !!prcsInfo.wkrTime ? prcsInfo.wkrTime + ' 시간' : '' }}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g">
                      <b>메모</b>
                    </td>
                    <td v-if="prcsInfo.isEdit">
                      <span>
                        <textarea ref="contentsRef"
                               class="w100"
                               maxlength="200"
                               v-model="prcsInfo.contents"/>
                      </span>
                    </td>
                    <td v-else>{{ prcsInfo.contents }}</td>
                  </tr>
                </table>
              </div>
              <div class="button_area w50">
                <ul>
                  <li class="w30">
                    <button v-show="prcsInfo.isEdit" @click="onClickProdPrcsCancel" class="mid_btn">취소</button>
                  </li>
                  <li class="w30">
                    <button v-show="prcsInfo.isEdit" @click="onClickProdPrcsSave" class="mid_btn orange">저장</button>
                  </li>
                  <li>
                    <button v-show="!!prcsInfo.prcsSeqNo && !prcsInfo.isEdit" @click="editProdPrcsInfo" class="mid_btn ">수정</button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiUtil from "@/api/api.util";
import ApiConfig from "@/api/api.config";
import EtnersEfsUtil from '@/common/etners.efs.util'
import { mapGetters } from 'vuex'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import Constant from '@/common/constant'


export default {
  name: 'SaveProcess',
  computed : {
    prodPrcsListDataSource: function () {
      const vm = this

      if(!vm.propsCmpyCd || vm.propsCmpyCd ===''){
        return []
      }

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/prod/prcs/depth`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.prcsInfo = { cmpyCd: vm.propsCmpyCd}

              return {
                loginUserToken: vm.$store.state.loginStore.lutk,
                searchCmpyCd : vm.propsCmpyCd,
                dopthRange : 2,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
              }
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.prodPrcsGridRef.dataSource.page() - 1)
                * vm.$refs.prodPrcsGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    ...mapGetters(['loginUserData'])
  },
  watch : {
    propsCmpyCd : function (newValue, oldValue){
      if(!newValue || newValue === ''){
        this.prcsInfo = {}
      }
    }
  },
  methods : {
    editProdPrcsInfo : function (){
      const vm = this
      // cloneDeep을 하지않으면 수정시 gird리스트의 값이 같이 바뀜
      let temp = _.cloneDeep(vm.prcsInfo)
      temp.isNew = false
      temp.isEdit = true
      vm.prcsInfo = temp
    },
    onClickProdPrcsCancel : function (){
      const vm = this
      vm.prcsInfo.isNew = false
      vm.prcsInfo.isEdit = false
      vm.getProdPrcsInfo()
    },
    onClickProdPrcsAdd : function (){
      const vm = this
      /** kendo 컴포넌트 특성상 객체인 value가 변경되어도 data상의 value는 바뀌지만
       * dom에는 값이 그대로 보여지고있기때문에 직접 kendo method로 초기화해줘야함
       * (cmpyNmRef, locaCdRef가 없는경우 코드 무시)
       **/
      vm.$refs.prodPrcsGridRef.kendoWidget().dataSource.read()
      vm.$refs.prcsRgstDateRef?.kendoWidget().value(null)
      vm.$refs.prcsDepth1Ref?.kendoWidget().value('')
      vm.$refs.wkrCntRef?.kendoWidget().value(null)
      vm.$refs.evtWkrCntRef?.kendoWidget().value(null)
      vm.$refs.maxRuntimeRef?.kendoWidget().value(null)
      vm.$refs.wkrTimeRef?.kendoWidget().value(null)
      vm.prcsInfo = {
        isNew : true,
        isEdit : true,
        cmpyCd : vm.propsCmpyCd
      }
    },
    onChangeGrid (ev) {
      const vm = this
      let cellIdx = window.event.target.cellIndex

      if (!isNaN(cellIdx)) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.searchInfo = {
          prcsSn : rowData.prcsSn,
          prcsSeqNo : rowData.prcsSeqNo,
          cmpyCd : vm.propsCmpyCd,
        }
        this.getProdPrcsInfo()
      }
    },
    getProdPrcsInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/prod/prcs/${vm.searchInfo.cmpyCd}/${vm.searchInfo.prcsSn}/${vm.searchInfo.prcsSeqNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.prcsInfo = response.data.resultData
              vm.prcsInfo.calcStdNo = vm.searchInfo.calcStdNo
              vm.prcsInfo.VModelPrcsRgstDate = EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(vm.prcsInfo.prcsRgstDate))
              vm.prcsInfo.isNew = false
              vm.prcsInfo.isEdit = false
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickProdPrcsSave () {
      const vm = this

      if (vm.validator()) {
        kendo.confirm(`저장 하시겠습니까?`).done(
            function () {
              vm.prodPrcsSave()
            })
      }
    },
    prodPrcsSave() {

      const vm = this
      let resultData

      if(!!vm.prcsInfo.VModelPrcsRgstDate){
        vm.prcsInfo.prcsRgstDate = EtnersDateTimeUtil.dateToYYYYMMDD(vm.prcsInfo.VModelPrcsRgstDate)
      }

      if(vm.prcsInfo.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/prod/prcs`, vm.prcsInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/prod/prcs/${vm.searchInfo.cmpyCd}/${vm.searchInfo.prcsSn}/${vm.searchInfo.prcsSeqNo}`, vm.prcsInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }

      function completeApi() {
        if(!!resultData){
          let prodPrcsGridRef = vm.$refs.prodPrcsGridRef.kendoWidget()
          prodPrcsGridRef.dataSource.read()
          kendo.alert('저장되었습니다.').bind('close', function() {
            let girdData = prodPrcsGridRef.items()
            girdData.each(i=>{
              let rowData = prodPrcsGridRef.dataItem(girdData[i])
              if(rowData.prcsSn === resultData.prcsSn && rowData.prcsSeqNo === resultData.prcsSeqNo){
                prodPrcsGridRef.select(`tr:eq(${i})`)
                vm.searchInfo = resultData
                vm.getProdPrcsInfo()
              }
            })
          })
        }else {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
        }
      }
    },
    // onClickProdPrcsDelete : function (){
    //   const vm = this
    //   kendo.confirm(`삭제 하시겠습니까?`).done(function () {
    //     vm.deleteProdPrcs()
    //   })
    // },
    // deleteProdPrcs : function (){
    //   const vm = this
    //   ApiUtil.delete(`${ApiConfig.efsDomain}/efs/prodPrcs/${vm.prcsInfo.cmpyCd}/${vm.prcsInfo.locaCd}/${vm.prcsInfo.prodPrcsCd}`, vm.prcsInfo)
    //   .then(response => {
    //
    //     if (response.data === undefined || response.data.resultStatus === undefined) {
    //       kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
    //       return false
    //     }
    //     if (response.data.resultStatus.messageCode !== '2000') {
    //       kendo.alert(response.data.resultStatus.messageText)
    //       return false
    //     }
    //     vm.$refs.prodPrcsGridRef.kendoWidget().dataSource.read()
    //     this.prcsInfo = {}
    //     kendo.alert("삭제되었습니다.")
    //   })
    // },
    getDataArray: function () {
      const vm = this
      let param = {
        searchCmpyCd : vm.propsCmpyCd,
        depthRange : 1
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/prcs/depth`, param).then((response) => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.dataArray = response.data.resultData
      })
      .catch((error) => {
        console.log(error);
      });
    },

    validator(){
      const vm = this
      if(!vm.prcsInfo.VModelPrcsRgstDate){
        kendo.alert("공정적용일을 선택해주세요.").bind('close', function() {
          vm.$refs.prcsRgstDateRef.kendoWidget().open()
        })
        return
      }
      if(!vm.prcsInfo.prcsDepth1){
        kendo.alert("공정파트구분을 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.prcsDepth1Ref.kendoWidget().toggle()
          }, 10)
        })
        return
      }
      if(!vm.prcsInfo.prcsDepth2Nm){
        kendo.alert("공정기록구분을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.prcsDepth2NmRef.focus()
          }, 10)
        })
        return
      }
      if(!vm.prcsInfo.wkrCnt){
        kendo.alert("작업인원을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.wkrCntRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      if(!vm.prcsInfo.prcsDepth1){
        kendo.alert("기준런타임을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.maxRuntimeRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      if(!vm.prcsInfo.wkrTime){
        kendo.alert("작업시간을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.wkrTimeRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      return true
    }
  },
  data () {
    const headerAttributes = { style: 'text-align: center;', }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const rowRowAttributes = { class :" #= autoGenerateYn === 'Y' ? ' bg_g': '' # " }
    return {
      Constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      locaPropsCmpyCd : '',
      prcsInfo : { isEdit : false},
      searchInfo : {},
      propsCmpyCd : '',
      dataArray : [],
      prodPrcsGridColumns: [
        {
          field: "prcsDepth1Nm",
          title: "공정파트구분",
          width: "40%",
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
        {
          field: "prcsDepth2Nm",
          title: "공정기록구분",
          width: "60%",
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
